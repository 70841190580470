import React, { useState } from 'react';
import { TextField } from '.';
import validateEmail from '../../../../utils/validateEmail';
import { Button } from '../../../v1/atoms/Button';
import cn from 'classnames';
import { ArrowLeft } from '../../../v1/atoms/icons';
import { useAnalytics, useAnalyticsErrorEvents } from '../../../../utils/analytics';

type Props = {
  pathname: string;
  formName: string;
  className?: string;
  formTitle?: string;
  buttonLabel?: string;
  queryParams?: string;
  analyticsInfo: string;
};

const LandingPageForm: React.FC<Props> = ({
  className,
  pathname,
  formName,
  formTitle = 'Get your quote in 2 minutes or less!',
  buttonLabel = 'Get my quote',
  analyticsInfo = 'marketing',
}) => {
  const { track } = useAnalytics();

  const [errors, setErrors] = useAnalyticsErrorEvents(analyticsInfo);

  const [userData, setUserData] = useState<Record<string, string>>({
    fullName: '',
    company: '',
    email: '',
  });

  const [isClean, setIsClean] = useState<Record<string, boolean>>({
    fullName: true,
    company: true,
    email: true,
  });

  const [isChanged, setIsChanged] = useState<Record<string, boolean>>({
    fullName: false,
    company: false,
    email: false,
  });

  const validationMessage: Record<string, Record<string, string>> = {
    email: {
      required: 'Please enter an email address',
      invalid: 'Please enter a valid email address',
    },
    company: {
      required: 'Please enter a company name',
      invalid: 'Please enter a valid company name',
    },
    fullName: {
      required: 'Please enter your full name',
      invalid: 'Please enter a valid full name',
    },
  };

  const alphaNumericRegex = new RegExp(/^[\p{L}']{1}[ \p{L}'-.]*[\p{L}.]{1}$/u);

  const onChangeHandler = (value: string, fieldName: string) => {
    if (!isChanged[fieldName]) {
      setIsChanged({ ...isChanged, [fieldName]: true });
      track(`Changed ${fieldName} (${analyticsInfo})`);
    }

    const trimmedValue = value.trim().length;
    if (trimmedValue >= 2 && alphaNumericRegex.test(value)) {
      setErrors({ ...errors, [fieldName]: null });
    }
    setIsClean({ ...isClean, [fieldName]: false });
    setUserData({ ...userData, [fieldName]: value });
  };

  const onBlurHandler = (value: string, fieldName: string) => {
    const trimmedValue = value.trim().length;
    if (trimmedValue === 0) {
      setErrors({ ...errors, [fieldName]: 'required' });
    } else if (trimmedValue <= 1 || trimmedValue > 64) {
      setErrors({ ...errors, [fieldName]: 'invalid' });
    } else if (!alphaNumericRegex.test(value)) {
      setErrors({ ...errors, [fieldName]: 'invalid' });
    } else {
      setErrors({ ...errors, [fieldName]: null });
    }
  };

  const onEmailBlurHandler = (value: string, fieldName: string) => {
    const trimmedValue = value.trim().length;
    if (trimmedValue === 0) {
      setErrors({ ...errors, [fieldName]: 'required' });
    } else if (trimmedValue <= 1) {
      setErrors({ ...errors, [fieldName]: 'invalid' });
    } else if (!validateEmail(value)) {
      setErrors({ ...errors, [fieldName]: 'invalid' });
    } else {
      setErrors({ ...errors, [fieldName]: null });
    }
  };

  const onEmailChangeHandler = (value: string, fieldName: string) => {
    if (!isChanged[fieldName]) {
      setIsChanged({ ...isChanged, [fieldName]: true });
      track(`Changed ${fieldName} (${analyticsInfo})`);
    }
    if (value.trim().length >= 2 || validateEmail(value)) {
      setErrors({ ...errors, [fieldName]: null });
    }
    setUserData({ ...userData, [fieldName]: value.trim() });
    setIsClean({ ...isClean, [fieldName]: false });
  };

  const hasError = (fieldName: string) => {
    const error = errors[fieldName];
    if (error) {
      return validationMessage[fieldName][error];
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const formIsChanged = Object.values(isChanged).every((value) => value);
    if (!formIsChanged) {
      setErrors({
        fullName: 'required',
        company: 'required',
        email: 'required',
      });
      e.preventDefault();
    }

    const formHasErrors = !!Object.keys(errors).find((value) => !!errors[value]);
    if (formHasErrors) {
      e.preventDefault();
    }
  };

  return (
    <form
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      method="POST"
      action={`${pathname}/?formSubmit=success`}
      name={formName}
      className={cn('bg-white p-8 m-auto sm:max-w-[500px] ', className)}
      onSubmit={handleSubmit}
    >
      <h3 className="text-2xl font-semibold mb-9">{formTitle}</h3>

      <TextField
        label="Full Name"
        id="fullName"
        type="text"
        placeholder="John Goldberg"
        autoComplete="full-name"
        className="!mb-8"
        errorMessage={hasError('fullName')}
        onBlurHandler={(e) => onBlurHandler(e.target.value, e.target.name)}
        onChange={(e) => onChangeHandler(e.target.value, e.target.name)}
      />
      <TextField
        label="Company"
        id="company"
        type="text"
        required
        className="!mb-8"
        placeholder="Homeppl"
        autoComplete="company"
        errorMessage={hasError('company')}
        onBlurHandler={(e) => onBlurHandler(e.target.value, e.target.name)}
        onChange={(e) => onChangeHandler(e.target.value, e.target.name)}
      />
      <TextField
        label="Email"
        id="email"
        type="email"
        required
        className="!mb-8"
        placeholder="john.goldberg@homeppl.com"
        autoComplete="email"
        errorMessage={hasError('email')}
        onBlurHandler={(e) => onEmailBlurHandler(e.target.value, e.target.name)}
        onChange={(e) => onEmailChangeHandler(e.target.value, e.target.name)}
      />
      <p className="max-w-7.5 text-grey-700 mb-8 text-sm">
        By proceeding you agree to
        <a href="https://www.guarantid.com/terms-of-service" className="text-blue-600 font-medium">
          {' '}
          Guarantid's Terms and Conditions
        </a>{' '}
        and{' '}
        <a href="https://www.guarantid.com/privacy" className="text-blue-600 font-medium">
          Privacy Policy
        </a>
      </p>
      <Button type="submit" label={buttonLabel} className="w-full sm:max-w-fit" Icon={ArrowLeft} />
      <input type="hidden" name="form-name" value={formName} />
    </form>
  );
};

export default LandingPageForm;
