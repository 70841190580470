import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/v2/templates/Layout';
import Grid from '../../components/v1/atoms/grid';
import useStoryblok from '../../lib/storyblok';
import Bloks from '../../components/atoms/Bloks';
import { Section } from '../../components/v2/templates/Section';
import LandingPageForm from '../../components/v2/molecules/Form/landingPage';
import SofaIllustration from '../../images/Sofa_illustration.svg';
import TrustpilotLogo from '../../images/TrustpilotLogo.svg';
import TrustpilotStars from '../../images/TrustpilotStars.svg';
import DynamicComponent from '../../components/dynamicComponent';
import { StoryblokPage } from '../../types';
import cn from 'classnames';

const GuarantidHome: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  return (
    <Layout
      location={location}
      headerData={content.header}
      footerData={content.footer}
      hideNavMenu
      light={false}
      headerVisibleButtonIndex={-1}
    >
      <Bloks bloks={content.SEO} />
      <Section curved hero className="pt-10 pb-25" noGrid curveColor="primary">
        <Grid className="relative">
          <div className="col-span-full text-center lg:text-left lg:col-span-6 lg:col-start-1 text-white mt-10">
            <h1
              className={cn(
                'text-3xl font-serif md:text-6xl text-white mb-6 text-center lg:text-left',
              )}
            >
              {content.title}
            </h1>
            <p className="text-base md:text-2xl pb-6 text-center lg:text-left">
              {content.description}
            </p>

            <a href="https://uk.trustpilot.com/review/homeppl.com" target="_blank" rel="noreferrer">
              {/* //TODO replace this with a widget ASAP instead of static image :/  */}
              <span className="inline-flex items-center flex-wrap justify-center">
                <span className="mb-1 md:mb-0 inline-flex items-center justify-center">
                  <span className="text-xl md:text-2xl  font-semibold">Excellent</span>
                  <img height={27} src={TrustpilotStars} alt="4.5/5 star rating" className="px-2" />
                </span>

                <span className="inline-flex items-center justify-center">
                  <span className="font-semibold text-base md:text-lg">260 reviews on</span>
                  <img height={22} src={TrustpilotLogo} alt="TrustPilot Logo" className="px-2" />
                </span>
              </span>
            </a>
          </div>
          <div className="col-span-full mt-10 lg:absolute lg:right-0 lg:top-[10px]">
            <LandingPageForm
              className="w-full col-span-4 sm:col-span-8 lg:col-start-2 lg:col-end-7 lg:shadow-2xl"
              queryParams={location.search}
              analyticsInfo="marketing - guarantid"
              buttonLabel="Send me more info"
              formTitle="Book a short 15 minute session to test our technology"
              pathname={`/${story.full_slug}`}
              formName="linkedin-lp-form"
            />
          </div>
        </Grid>
      </Section>

      <DynamicComponent blok={{ ...content.sections[0] }} />
      <DynamicComponent blok={{ ...content.sections[1] }} />
      <DynamicComponent blok={{ ...content.sections[2] }} />
      <DynamicComponent blok={{ ...content.sections[3] }} />

      <Section
        curved
        noGrid
        className="!mb-[10rem] bg-blue lg:!mt-[6rem] lg:!pt-0"
        curveColor="primary"
        id="ContactUs"
      >
        <Grid className="relative pb-[270px] sm:pb-[290px] lg:p-0">
          <LandingPageForm
            className="sm:max-w-none w-full col-span-4 sm:col-span-8 lg:col-start-2 lg:col-end-7 mt-11 lg:shadow-2xl lg:mt-14 lg:-mb-[60px]"
            queryParams={location.search}
            analyticsInfo="marketing - guarantid"
            buttonLabel="Request more info"
            formTitle="Get case studies & tech info delivered to your inbox"
            pathname={`/${story.full_slug}`}
            formName="linkedin-lp-form"
          />
          <img
            src={SofaIllustration}
            alt="sofa with flying papers from the seat"
            className="absolute -bottom-20  left-3.5 w-[375px] sm:w-[343px] lg:w-[550px] xl:w-[681] sm:right-5 sm:left-auto sm:-bottom-16"
            loading="lazy"
          />
        </Grid>
      </Section>

      <DynamicComponent blok={{ ...content.sections[4] }} />
    </Layout>
  );
};

export default GuarantidHome;

export const query = graphql`
  query LinkedinLPQuery {
    storyblokEntry(full_slug: { eq: "linkedin/tenant-referencing" }) {
      content
      name
      full_slug
    }
  }
`;
